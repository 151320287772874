<template>
  <v-dialog v-model="dialog" width="750px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon color="red"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        Delete Company Note
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="font-weight-bold">
              Are you sure you want to delete this note?
            </v-col>
          </v-row>
          <v-row>
            <v-col align="right" class="pr-0">
              <v-btn
                plain
                class="msaBlue--text font-weight-bold"
                @click="close()"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col align="right" class="pl-0" md="2">
              <v-btn class="msaBlue white--text" @click="confirm()">
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'CompanyNoteDeletion',
  props: {
    itemId: {
      type: Number,
    },
  },
  computed: {},
  watch: {},
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    confirm() {
      let params = {
        noteId: this.itemId,
        loaderText: 'Saving...',
      };

      let url = 'delete-company-note?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.close();
          this.$emit('reload');
        })
        .catch(error => error);
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
  },
};
</script>
<style scoped></style>
