<template>
  <div>
    <CompanyNoteDetail ref="companyNoteDetail" @reload="getAll" />
    <v-container fluid>
      <v-row>
        <v-col>
          <SearchBar @search="onSearch($event)" ref="searchBar" />
        </v-col>
        <v-col class="pb-0" align="right">
          <v-btn
            @click="showNote({ id: -1, note: '' })"
            class="msaBlue white--text"
            v-blur
            data-testid="add-note"
          >
            <v-icon :class="$vuetify.breakpoint.mdAndUp ? 'pr-2' : ''">
              mdi-plus
            </v-icon>
            <span v-if="$vuetify.breakpoint.mdAndUp">Note</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="msaBlue white--text"> Notes </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="itemsCount"
              :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
              :options.sync="options"
              @update:options="updateUrl()"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="msaBlue--text">
                    <span class="text-subtitle-2 font-weight-bold">{{
                      item.note
                    }}</span>
                  </td>
                  <td align="center">
                    {{ item.createdAt | filterAsLocalDate() }}
                  </td>
                  <td align="center">
                    {{ item.createdBy }}
                  </td>
                  <td align="center">
                    {{ item.updatedAt | filterAsLocalDate() }}
                  </td>
                  <td align="center">
                    {{ item.updatedBy }}
                  </td>
                  <td class="actions" align="right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip, attrs }">
                        <v-btn
                          v-blur
                          text
                          small
                          v-bind="attrs"
                          v-on="{ ...tooltip }"
                          @click="showNote(item)"
                          data-testid="edit-note"
                        >
                          <v-icon color="msaBlue"> mdi-pencil </v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <CompanyNoteDeletion :itemId="item.id" @reload="getAll()" />
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-data-table
        v-if="$vuetify.breakpoint.smAndDown"
        hide-default-header
        :items="items"
        :server-items-length="itemsCount"
        :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
        :options.sync="options"
        @update:options="updateUrl()"
      >
        <template v-slot:item="{ item }">
          <v-card class="my-1">
            <v-card-title class="msaBlue white--text">
              Created By {{ item.createdBy }} <br />
              <span class="text-body-2 font-italic">
                {{ item.createdAt | filterAsLocalDate() }}
              </span>
            </v-card-title>
            <v-card-subtitle class="msaBlue white--text pt-3 mb-3">
              Updated By {{ item.updatedBy }} <br />
              <span class="text-body-2 font-italic">
                {{ item.updatedAt | filterAsLocalDate() }}
              </span>
            </v-card-subtitle>
            <v-card-text>
              {{ item.note }}
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-col align="right">
                    <v-tooltip>
                      <template v-slot:activator="{ on: tooltip, attrs }">
                        <v-btn
                          v-blur
                          text
                          small
                          v-bind="attrs"
                          v-on="{ ...tooltip }"
                          @click="showNote(item)"
                          data-testid="edit-note"
                        >
                          <v-icon color="msaBlue"> mdi-pencil </v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-col>
                  <v-col>
                    <CompanyNoteDeletion :itemId="item.id" @reload="getAll()" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
import CompanyNoteDeletion from '@/components/CompanyNotes/CompanyNoteDeletion.vue';
import SearchBar from '@/components/SearchBar.vue';
import CompanyNoteDetail from '@/components/CompanyNotes/CompanyNoteDetail.vue';
export default {
  name: 'CompanyNotes',
  components: {
    SearchBar,
    CompanyNoteDetail,
    CompanyNoteDeletion,
  },
  data() {
    return {
      reload: false,
      search: '',
      items: [],
      itemsCount: 0,
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
        sortBy: ['createdAt'],
        sortDesc: [true],
      },
      headers: [
        {
          text: 'Note',
          value: 'note',
          align: 'start',
          class: 'lightBg',
          sortable: false,
        },
        {
          text: 'Created At',
          value: 'createdAt',
          align: 'center',
          class: 'lightBg',
          width: '120px',
        },
        {
          text: 'Created By',
          value: 'CreatedBy',
          align: 'center',
          class: 'lightBg',
          width: '120px',
        },
        {
          text: 'Updated At',
          value: 'updatedAt',
          align: 'center',
          class: 'lightBg',
          width: '120px',
        },
        {
          text: 'Updated By',
          value: 'updatedBy',
          align: 'center',
          class: 'lightBg',
          width: '120px',
        },
        {
          text: 'Actions',
          align: 'center',
          class: 'lightBg',
          width: '140px',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    showNote(item) {
      this.$refs.companyNoteDetail.show(item);
    },
    getAll() {
      if (this.search == null) {
        this.search = '';
      }

      let params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search,
        sortDesc:
          this.options.sortDesc[0] != undefined
            ? this.options.sortDesc[0]
            : true,
        sortBy:
          this.options.sortBy[0] != undefined
            ? this.options.sortBy[0]
            : 'createdAt',
        companyId: this.$route.params.companyId,
        loaderText: 'Loading...',
      };

      let url = 'get-company-notes?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.count;
          this.$store.commit('updateSelectedCompany', {
            id: response.data.company.id,
            name: response.data.company.name,
            status: response.data.company.status,
            companyGroupId: response.data.company.companyGroupId,
          });
        })
        .catch((error) => error);
    },
    onSearch(event) {
      this.search = event;
      this.options.page = 1;
      this.updateUrl();
    },
    getRouteQueries() {
      const page = this.$route.query.page;
      this.options.page =
        page == undefined ||
        isNaN(page) ||
        parseInt(page) < this.$constants.PAGINATION.DEFAULT_PAGE
          ? this.$constants.PAGINATION.DEFAULT_PAGE
          : parseInt(page);

      const perPage = this.$route.query.perPage;
      this.options.itemsPerPage =
        perPage == undefined ||
        isNaN(perPage) ||
        !this.$constants.PAGINATION.DEFAULT_FOOTER_PROPS[
          'items-per-page-options'
        ].includes(parseInt(perPage))
          ? this.$constants.PAGINATION.DEFAULT_PERPAGE
          : parseInt(perPage);

      const search = this.$route.query.search;
      this.search =
        search == undefined || search.trim().length == 0 ? '' : search.trim();

      const sortBy = this.$route.query.sortBy;
      const sortBys = this.headers.map((i) => i.value);
      this.options.sortBy[0] =
        sortBy == undefined || !sortBys.includes(sortBy) ? 'createdAt' : sortBy;

      const sortDesc = this.$route.query.sortDesc;
      this.options.sortDesc[0] =
        sortDesc == undefined || sortDesc ? true : false;
    },
    updateUrl() {
      const params = {};
      if (this.options.page != this.$constants.PAGINATION.DEFAULT_PAGE) {
        params.page = this.options.page;
      }
      if (
        this.options.itemsPerPage != this.$constants.PAGINATION.DEFAULT_PERPAGE
      ) {
        params.perPage = this.options.itemsPerPage;
      }
      if (this.search != '') {
        params.search = this.search;
      }

      if (this.options.sortBy[0] != 'createdAt') {
        params.sortBy = this.options.sortBy[0];
      }
      if (!this.options.sortDesc[0]) {
        params.sortDesc = this.options.sortDesc[0];
      }

      let changed = false;
      if (Object.keys(params).length != Object.keys(this.$route.query).length) {
        changed = true;
      } else {
        for (const key in params) {
          if (params[key] != this.$route.query[key]) {
            changed = true;
          }
        }
      }

      if (changed) {
        this.$router.push({ name: this.$route.name, query: params });
      }
    },
  },
  mounted() {
    this.getAll();
    if (this.search != '') {
      this.$refs.searchBar.setSearch(this.search);
    }
  },
  created() {
    this.getRouteQueries();
  },
};
</script>
<style></style>
