<template>
  <div>
    <v-dialog v-model="dialog" width="750px" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col class="font-weight-bold"> Note: </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-textarea
                    class="py-0"
                    outlined
                    counter
                    maxlength="1000"
                    v-model="note.note"
                    :rules="rules"
                    data-testid="note-text"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="right" class="pr-0">
                  <v-btn
                    plain
                    class="msaBlue--text font-weight-bold"
                    @click="close()"
                  >
                    Cancel
                  </v-btn>
                </v-col>
                <v-col align="right" class="pl-0" md="2">
                  <v-btn
                    class="msaBlue white--text"
                    @click="saveDetail()"
                    data-testid="save-note"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'CompanyNoteDetail',
  data() {
    return {
      note: {
        id: -1,
        note: '',
      },
      dialog: false,
      valid: false,
      rules: [(v) => !!v || 'This field is required.'],
    };
  },
  computed: {
    title() {
      return this.note.id === -1 ? 'Add Company Note' : 'Edit Company Note';
    },
  },
  watch: {},

  methods: {
    saveDetail() {
      if (this.$refs.form.validate()) {
        let params = {
          noteId: this.note.id,
          note: this.note.note,
          companyId: this.$store.getters.selectedCompany.id,
          loaderText: 'Saving...',
        };

        let url = 'save-company-note-detail?format=json';

        this.$axios
          .post(url, params)
          .then(() => {
            this.$emit('reload');
            this.close();
          })
          .catch((error) => error);
      }
    },
    show(note) {
      this.note = JSON.parse(JSON.stringify(note));
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },
  },
};
</script>
<style scoped></style>
